/* Privacy Page CSS */
.privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.privacy-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.privacy-container h2 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.privacy-container p {
    line-height: 1.6;
    margin-bottom: 20px;
}

.privacy-container a {
    color: #007bff;
}

.privacy-container a:hover {
    text-decoration: underline;
}

/**
Qutes CSS
*/

/*
Planed color scheme
#fb8122
#1d2228
#e1e2e2
*/

.App-Body{
  height: 100vh;
}

.App-quotes-title{
    color: #000000;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top:1.6rem ;
}


.App-quote{
    color: #000000;
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 25%;
    padding-right: 25%;
}

.App-button-quote{
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 5.5rem;
    padding-left: 25%;
    padding-right: 25%;
}

.App-button-quote:hover{
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 5.5rem;
    padding-left: 25%;
    padding-right: 25%;
}

footer {
        padding-top: 2rem;
        left: 0;
        bottom: 0;
        height: auto;
        width: 100%;
        bottom: 0;
    }

.custom-card {
  width: 18rem;
  margin-bottom: 20px; /* Adjust as needed */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-card .card-body {
  padding: 1.25rem;
}

.custom-card .card-title {
  font-weight: bold;
}

.custom-card .card-text {
  color: #666;
}

.custom-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-display-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 7.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  justify-content: center;
}

.custom-card {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .card-display-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.skill-display-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.skill-display {
  background-color: #1a1a1a; /* Dark background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  width: 75%; /* Adjust width to fill 75% of the screen */
  max-width: 100vw;
}

.skill-display h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.skill-item {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #444; /* Border between items */
}

.skill-name {
  font-size: 18px;
  color: #fff; /* White text */
}

.progress-container {
  width: 70%; /* Adjust as needed */
}

.custom-progress-bar {
  height: 25px; /* Adjust height */
  border-radius: 15px; /* Rounded corners */
}

.custom-progress-bar .progress-bar {
  border-radius: 15px; /* Rounded corners */
}

@media (max-width: 1300px) {
  .skill-display-container {
    height: auto;
    padding: 20px;
}

.skill-display {
    width: 100%; /* Adjust width to fill entire screen */
    border-radius: 0; /* Remove border-radius */
    box-shadow: none; /* Remove shadow */
}
}
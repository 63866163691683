/* ContactPage.css */

.contact-page {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 50px;
  }
  
  .contact-info {
    flex: 1;
    margin-right: 50px;
  }
  
  .contact-form {
    flex: 1;
  }
  
  .response-message {
    margin-top: 20px;
    font-weight: bold;
    color: green;
  }
  